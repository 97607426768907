/*
Number Reg Ex:
- If decimal is allowed:
	-- decimal separator is localized: French decimal separator = comma, english and default is period
	-- number may start with a decimal
- Numbers can be optionally grouped in thouands using a localized group separator: French grouping separator = space, 
	english and default is comma
- Grouping is optional, but must be consistent, so if start with grouping then must continue with grouping
*/
export const REGEX_NUMBER_NO_DECIMALS_EN = /^-?\d{0,3}((,\d{3})*|[\d]*)?$/;
export const REGEX_NUMBER_NO_DECIMALS_FR = /^-?\d{0,3}((\s\d{3})*|[\d]*)?$/;
export const REGEX_NUMBER_WITH_OPTIONAL_DECIMALS_FR = /^-?\d{0,3}((\s\d{3})*|[\d]*)?((,[\d]+)|,)?$/;
export const REGEX_NUMBER_WITH_OPTIONAL_DECIMALS_EN =
	/^-?\d{0,3}((,\d{3})*|[\d]*)?((\.[\d]+)|\.)?$/;

/*
Currency Reg Ex:
Allows formats: 
	- decimals only (starting with decimal separator), 
	- no decimals, 
	- full with decimals
Standard number formatting, with up to 2 decimal places
Allows optional Dollar sign
		
		*/
export const REGEX_NUMBER_CURRENCY_EN = /^[$]?\d{0,3}((,\d{3})*|[\d]*)?((\.\d{1,2})|(\.))?$/;
export const REGEX_NUMBER_CURRENCY_FR = /^\d{0,3}((\s\d{3})*|[\d]*)?((,\d{1,2})|(,))?[$]?$/;

/**
 * Parses a String into a numeric value by removing any localized formatting characters and then
 * converting the String to a Number object
 * if locale is not specified, assume the default locale is 'en'
 * @param {String} locale a valid locale value that is recognized by Intl.NumberFormat
 * (See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat and
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/supportedLocalesOf
 * )
 */
export function parseNumber(value, locale) {
	//Have to use == null because we might get 0 (zero) whcih will be incorrectly validated as true and return null
	//if we do if(value)
	//Trim and lenght check is included to ensure that empty Strings are not converted to zero - empty Strings
	//should be handled as null values.
	if (value == null || ('' + value).trim().length <= 0) {
		return null;
	}

	if (!locale) {
		locale = 'en';
	}

	if (isNaN(value)) {
		//Determine what is considered by the locale as a decimal separator. We pass in a number where the second char is
		//an english number's decimal (.), then convert the String to the localized Stirng and read the
		//character in the second char or the localized string.
		const decimalSeparator = Intl.NumberFormat(locale).format('1.1').charAt(1);

		//Strip any formatting characters, except the decimal separator and potential - (minus) signage
		let regexpRemoveFormatChars = new RegExp(`[^0-9^-^${decimalSeparator}]`, 'g');
		let replaceDecimalSeparatorRegEx = new RegExp(`[${decimalSeparator}]`, 'g');

		try {
			value = value?.replace(regexpRemoveFormatChars, '');
			if (replaceDecimalSeparatorRegEx) {
				value = value?.replace(replaceDecimalSeparatorRegEx, '.');
			}
		} catch (error) {
			//This normally happens if the value is not a number, but also not a Sttring, and potentially not null
			//so we do nto want to keep that value - just return null
			return null;
		}
	}

	return Number(value);
}

export function isNumeric(str) {
	if (typeof str != 'string') return false; // we only process strings!
	return (
		!isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
		!isNaN(parseFloat(str))
	); // ...and ensure strings of whitespace fail
}

//Will format to currency input including up to 2 decimal value
export function CurrencyFormat(amount, locale) {
	const formatter = new Intl.NumberFormat(`${locale}-CA`, {
		style: 'currency',
		currency: 'CAD',
		minimumFractionDigits: 2
	});
	if (amount != null && !isNaN(amount)) {
		const formattedAmount = formatter.format(amount);
		return locale === 'fr' ? formattedAmount.replace('CA', '') : formattedAmount;
	}
}
