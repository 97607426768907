<template>
	<CoverageTemplate :page-title="$t('title')" :automation-id="getAutomationId('rhip')">
		<BaseCard :title="$t('label.desc')" :automation-id="getAutomationId('rhip')">
			<BCardText :automation-id="getAutomationId('rhip')">
				{{ $t('content.topParagraph') }}
			</BCardText>
		</BaseCard>
		<BaseCard :title="$t('label.rhipBalance')" :automation-id="getAutomationId('rhip')">
			<BCardText :automation-id="getAutomationId('rhip-balance-para')">
				{{ $t('content.rhipBalance') }}
			</BCardText>
			<div v-if="canDisplayAmount">
				<p class="balance">
					{{ $t('content.remainingBalance') }}
					<span class="ml-3" :automation-id="getAutomationId('rhip-balance-amount')">{{
						userRHIPAmount
					}}</span>
				</p>
			</div>
			<BaseButton
				:label="$t('button.summary')"
				:automation-id="getAutomationId('rhip-report')"
				@click="getSummary()"
			/>
		</BaseCard>
	</CoverageTemplate>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import BreadcrumbsManager from '@/mixins/BreadcrumbsManager';
import { DASHBOARD, COVERAGE } from '@/constants/Routes';
import CoverageTemplate from '@/pages/coverage/CoverageTemplate.vue';
import BaseCard from '@/components/common/card/BaseCard.vue';
import BaseButton from '@/components/common/base/BaseButton';
import { BCardText, BRow, BCol } from 'bootstrap-vue';
import RHIP from '@/models/coverage/RHIP';
import { CurrencyFormat } from '@/utils/NumberUtil';

@Component({
	name: 'RetirementHealthInsuranceProgramPage',
	components: {
		CoverageTemplate,
		BaseCard,
		BCardText,
		BaseButton,
		BCol,
		BRow
	},
	mixins: [IdMixin, BreadcrumbsManager]
})
export default class RetirementHealthInsuranceProgramPage extends Vue {
	userAmount = null;
	async created() {
		this.setBreadcrumbPath(
			[
				{ text: this.$t('breadcrumb.home', 'en'), to: { name: DASHBOARD } },
				{ text: this.$t('breadcrumb.myCoverage', 'en'), to: { name: COVERAGE.MY_COVERAGE } },
				{ text: this.$t('breadcrumb.rhip', 'en') }
			],
			[
				{ text: this.$t('breadcrumb.home', 'fr'), to: { name: DASHBOARD } },
				{ text: this.$t('breadcrumb.myCoverage', 'fr'), to: { name: COVERAGE.MY_COVERAGE } },
				{ text: this.$t('breadcrumb.rhip', 'fr') }
			]
		);

		this.userAmount = await RHIP.getAmount(
			sessionStorage.getItem('email'),
			sessionStorage.getItem('apiToken'),
			this.$store.state.i18n.locale
		);
	}

	get canDisplayAmount() {
		return this.userAmount !== 0 || this.userAmount !== null;
	}

	get userRHIPAmount() {
		if (this.userAmount) return CurrencyFormat(this.userAmount, this.$store.state.i18n.locale);
		return '';
	}

	async getSummary() {
		await RHIP.getReport(
			sessionStorage.getItem('email'),
			sessionStorage.getItem('apiToken'),
			this.$store.state.i18n.locale
		);
	}
}
</script>
<style lang="scss" scoped>
.balance {
	font-weight: 400;
}
</style>
>
<i18n>
{
  "en": {
    "title": "Retirement Health Insurance Program (RHIP)",
    "label": {
      "desc": "Description",
      "rhipBalance": "RHIP Balance"
    },
    "content": {
			"topParagraph": "For members of the Irving Flexible Benefits Plan (iflexplan), RHIP lets you set aside money on a pre-tax basis before you retire, to pay for health premiums and expenses after you retire.",
			"rhipBalance": "This report shows your current RHIP usage summary and balance.",
			"remainingBalance": "Current balance:"
    },
		"button": {
			"summary": "RHIP Summary"
		},
    "breadcrumb": {
      "home": "Home",
      "myCoverage": "Plan Coverage",
			"rhip": "Retirement Health Insurance Program (RHIP)"
    }
  },
  "fr": {
    "title": "Régime d’assurance maladie à la retraite (RAMR)",
    "label": {
      "desc": "Description",
      "rhipBalance": "Solde du RAMR"
    },
    "content": {
			"topParagraph": "Le RAMR permet aux adhérents du régime d’assurance flexible (régime iFlex) d’Irving de mettre de l’argent de côté avant impôt avant la retraite pour être en mesure de payer les primes et les dépenses liées aux soins de santé après la retraite.",
			"rhipBalance": "Ce rapport présente le relevé de votre utilisation actuelle et votre solde du RAMR.",
			"remainingBalance": "Solde actuel :"
		},
		"button": {
			"summary": "Sommaire du RAMR"
		},
    "breadcrumb": {
      "home": "Accueil",
      "myCoverage": "Couverture du régime",
			"rhip": "Régime d’assurance maladie à la retraite (RAMR)"
    }
  }
}
</i18n>
